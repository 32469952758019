<template>
  <div class="landing-container">
    <div class="landing-header">
      <img
        class="landing-header__logo"
        src="../assets/images/logoPrincipalClaim.png"
        alt="TuAppto"
      />
      <Button
        label="Iniciar Sesión"
        class="button landing-header__button"
        @click="goToLogin"
      />
    </div>
    <div class="landing-1">
      <h2>
        Plataforma 100% uruguaya que te ofrece
        <span>una solución global</span> en el uso diario de tu edificio, barrio
        o complejo.
      </h2>
    </div>
    <div class="landing-2">
      <img src="../assets/images/img1.jpg" alt="img1" />
    </div>
    <div class="landing-3">
      <h2>Soluciones de TuAppto...</h2>
      <div class="landing-3__container">
        <div class="landing-3__box">
          <img src="../assets/images/img2.png" alt="img2" />
        </div>
        <div class="landing-3__text">
          <p style="margin: 1rem 0;">
            Paga tus gastos y visualiza toda la información de los Gastos
            Comunes
          </p>
          <p style="margin: 1rem 0 1rem 4rem;">
            Recibe notificaciones de avisos de visitas y encomiendas
          </p>
          <p style="margin: 1rem 0 1rem 8rem;">
            Visualiza toda la información respecto de las asambleas y las
            resoluciones adoptadas
          </p>
          <p style="margin: 1rem 0;">Mensajería interna</p>
          <p style="margin: 1rem 0 1rem 4rem;">
            Gestiona votaciones y decisiones vinculadas al edificio
          </p>
          <p style="margin: 1rem 0 1rem 8rem;">
            Gestiona Áreas Comunes y sus reservas
          </p>
        </div>
      </div>
    </div>
    <div class="landing-4">
      <div class="landing-4__text">
        <h2>¿Cómo comenzar con TuAppto?</h2>
        <div class="landing-4__text-box">
          <h2>1-</h2>
          <p>Contacta con nosotros por nuestras vías de comunicación.</p>
        </div>
        <div class="landing-4__text-box">
          <h2>2-</h2>
          <p>
            Te daremos soporte en la migración de tu información y capacitación
            en el uso de la plataforma.
          </p>
        </div>
        <div class="landing-4__text-box">
          <h2>3-</h2>
          <p>
            Mejora el servicio a tus clientes y proporciona una imagen de
            solidez y modernidad a tu empresa.
          </p>
        </div>
      </div>
      <div class="landing-4__box">
        <img src="../assets/images/img3.jpg" alt="img3" />
      </div>
    </div>
    <div class="landing-contact">
      <h2>¿Estás con dudas? ¡Contáctanos!</h2>
      <div class="landing-contact__form">
        <div class="landing-contact__input-container">
          <p :class="contact.rules.name && 'label-error'">Nombre *</p>
          <InputText
            v-model="contact.data.name"
            type="text"
            placeholder="Ingresa tu nombre"
            :class="
              `landing-contact__input ${contact.rules.name && 'input-error'}`
            "
          />
        </div>
        <div class="landing-contact__input-container">
          <p :class="contact.rules.email && 'label-error'">Email *</p>
          <InputText
            v-model="contact.data.email"
            type="email"
            placeholder="Ingresa tu email"
            :class="
              `landing-contact__input ${contact.rules.email && 'input-error'}`
            "
          />
        </div>
        <div class="landing-contact__input-container">
          <p>Teléfono</p>
          <InputText
            v-model="contact.data.phone"
            type="text"
            placeholder="Introduce tu número de teléfono"
            class="landing-contact__input"
          />
        </div>
        <div class="landing-contact__input-container">
          <p :class="contact.rules.topic && 'label-error'">Asunto *</p>
          <InputText
            v-model="contact.data.topic"
            type="text"
            placeholder="Escribe el asunto"
            :class="
              `landing-contact__input ${contact.rules.topic && 'input-error'}`
            "
          />
        </div>
        <div class="landing-contact__input-container">
          <p :class="contact.rules.message && 'label-error'">Mensaje *</p>
          <Textarea
            v-model="contact.data.message"
            :autoResize="true"
            rows="4"
            placeholder="Escribe tu mensaje aquí..."
            :class="
              `landing-contact__input ${contact.rules.message && 'input-error'}`
            "
          />
        </div>
        <Button
          label="Enviar"
          class="button landing-header__button"
          style="width: 350px; margin: 2rem 0"
          @click="sendMessage"
        />
      </div>
    </div>
    <div class="landing-footer">
      <div class="landing-footer__logo">
        <img
          src="../assets/images/logoCentradoLinealBLANCO.png"
          alt="logoCentradoLinealBLANCO"
        />
      </div>
      <div class="landing-footer__contact">
        <h2>CONTACTO</h2>
        <p>Correo Electrónico: info@tuappto.com</p>
        <p>Teléfono: (+598) 098 596 805</p>
        <p>Edificio Libertad, Quebracho 2717, Montevideo - Uruguay</p>
      </div>
      <div class="landing-footer__follow">
        <h2>SÍGUENOS</h2>
        <a
          href="https://instagram.com/tuappto?igshid=Zjc2ZTc4Nzk="
          target="_blank"
          ><img src="../assets/images/instagram.png" alt="instagram"
        /></a>
        <a href="https://www.linkedin.com/company/tuappto/" target="_blank"
          ><img src="../assets/images/linkedin.png" alt="linkedin"
        /></a>
      </div>
      <div class="landing-footer__available">
        <h2>DISPONIBLE EN</h2>
        <a
          href="https://apps.apple.com/us/app/tuappto/id1592735150"
          target="_blank"
          ><img src="../assets/images/appleStore.png" alt="appleStore"
        /></a>
        <a
          href="https://play.google.com/store/apps/details?id=com.tuappto.prod"
          target="_blank"
          ><img src="../assets/images/googlePlay.png" alt="googlePlay"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watchEffect } from "vue";
import store from "../store";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const goToLogin = () => {
      router.push("/login");
    };

    const contact = reactive({
      data: {
        name: "",
        email: "",
        phone: "",
        topic: "",
        message: "",
      },
      rules: {
        name: false,
        email: false,
        topic: false,
        message: false,
      },
    });
    const validateContact = () => {
      let valid = true;

      if (!contact.data.name) {
        contact.rules.name = true;
        valid = false;
      }
      if (!contact.data.email) {
        contact.rules.email = true;
        valid = false;
      }
      if (!contact.data.topic) {
        contact.rules.topic = true;
        valid = false;
      }
      if (!contact.data.message) {
        contact.rules.message = true;
        valid = false;
      }

      return valid;
    };
    const resetContact = () => {
      contact.data.name = "";
      contact.data.email = "";
      contact.data.phone = "";
      contact.data.topic = "";
      contact.data.message = "";
      contact.rules.name = false;
      contact.rules.email = false;
      contact.rules.topic = false;
      contact.rules.message = false;
    };
    const sendMessage = async () => {
      if (!validateContact()) return;

      store.commit("setLoading", true);
      const response = await store.dispatch("AUTH_ACCESS", contact.data);
      store.commit("setLoading", false);
      if (response.ok) {
        store.state.toast.add({
          severity: "success",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
      resetContact();
    };
    watchEffect(() => {
      if (contact.data.name) contact.rules.name = false;
      if (contact.data.email) contact.rules.email = false;
      if (contact.data.topic) contact.rules.topic = false;
      if (contact.data.message) contact.rules.message = false;
    });
    return { goToLogin, contact, sendMessage };
  },
};
</script>

<style scoped>
.landing-header {
  border-bottom: 4px solid #1f404f;
  min-height: 120px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.landing-header__logo {
  height: 100px;
}
.landing-header__button {
  padding: 1rem;
  width: 180px;
  margin: 2rem;
  background-color: #1f404f;
  border-color: #1f404f;
  font-weight: 200;
}
.landing-header__button:hover {
  background-color: #1f404f;
  border-color: #1f404f;
  cursor: pointer;
}
.landing-1 {
  padding: 1rem;
}
.landing-1 > h2 {
  background-color: #1f404f;
  color: white;
  font-size: 2.5rem;
  text-align: center;
  padding: 2rem;
}
.landing-1 > h2 > span {
  background-color: #1fa5a7;
}
.landing-2 {
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing-2 > img {
  max-width: 800px;
}
.landing-3 {
  padding: 4rem 2rem;
  background-color: #1f404f;
}
.landing-3 > h2 {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: white;
}
.landing-3__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4rem;
}
.landing-3__box {
  padding: 1rem;
  background-color: white;
  border-radius: 4rem;
}
.landing-3__box > img {
  max-width: 500px;
}
.landing-3__text {
  padding: 2rem;
  color: white;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.landing-3__text > p {
  width: 280px;
}
.landing-4 {
  padding: 4rem 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 12px solid #1f404f;
}
.landing-4__text {
  max-width: 500px;
  background-color: #f7f7f7;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.landing-4__text > h2 {
  font-size: 3rem;
}
.landing-4__text-box {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.landing-4__text-box > h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.landing-4__text-box > p {
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;
}
.landing-4__box > img {
  max-width: 500px;
}
.landing-contact {
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landing-contact > h2 {
  text-align: center;
  font-size: 3rem;
}
.landing-contact__input-container {
  margin-top: 2rem;
}
.landing-contact__input {
  width: 350px;
  border: 0px;
  border-bottom: 1px solid black;
  border-radius: 0px;
  padding: 1rem;
}
.landing-contact__input:hover {
  border-color: black;
  border-radius: 0px;
  border: 1px solid black;
}
.landing-contact__input::placeholder {
  font-size: 0.8rem;
  color: black;
  font-weight: 300;
}
.landing-footer {
  padding: 2rem;
  background-color: #1f404f;
  color: white;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.landing-footer__contact > p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
.landing-footer__follow > a > img {
  display: block;
  width: 3rem;
  margin: 0.5rem auto;
}
.landing-footer__available > a > img {
  display: block;
  width: 8rem;
  margin: 0.5rem auto;
}
.landing-footer__logo,
.landing-footer__contact,
.landing-footer__follow,
.landing-footer__available {
  margin: 1rem;
}
.input-error {
  border-color: red;
  color: red;
}
.label-error {
  color: red;
}
</style>
